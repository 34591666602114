import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VModal from 'vue-js-modal'

Vue.config.productionTip = false

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: {
    height: '80%',
    scrollable: true,
    adaptive: true
  },
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
